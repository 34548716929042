import React from 'react';
import ReactDOM from 'react-dom';
import './assets/css/App.css';
import { BrowserRouter, Route, Routes, useNavigate } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';

import theme from './theme/theme';

import AuthLayout from 'layouts/auth';
import SignIn from 'views/auth/signIn';
import Dashboard from 'layouts/admin';
import Reports from 'views/admin/reports';
import Events from 'views/admin/events';
import EventPage from 'views/admin/events/pages/details';
import UpdateEventPage from 'views/admin/events/pages/update';
import CreateEventPage from 'views/admin/events/pages/create';
import CreateTicketPage from 'views/admin/events/pages/tickets/create';
import UpdateTicketPage from 'views/admin/events/pages/tickets/update';
import CreateInvitePage from 'views/admin/events/pages/invites';
import UsersPage from 'views/admin/users';
import CreateCompanyUserPage from 'views/admin/users/pages/create';
import MercadoPagoOauthTokenStatusPage from 'views/admin/payments/mercadopago/pages/status';
import MercadoPagoOauthTokenPage from 'views/admin/payments/mercadopago/pages/oauth';
import VenuesPage from 'views/admin/venues';
import CreateVenuePage from 'views/admin/venues/pages/create';
import UpdateVenuePage from 'views/admin/venues/pages/update';
import FinancePage from 'views/admin/finance';
import MercadoPagoOauthTokenCreatePage from 'views/admin/finance/pages/mercadopago_create';
import CreateProductPage from 'views/admin/events/pages/products/pages/create';
import UpdateProductPage from 'views/admin/events/pages/products/pages/update';


ReactDOM.render(
	<ChakraProvider theme={theme}>
		<React.StrictMode>
			<BrowserRouter>
			<Routes>
				<Route path="/*" element={<GlobalRedirect />} />
				<Route path="/auth/*" element={<AuthLayout />}>
					<Route path="sign-in" element={<SignIn />} />
				</Route>
				<Route path="/admin/*" element={<Dashboard />}>
					<Route path="" element={<Reports />} />
					<Route path="payment/">
						<Route path="mercado-pago/status" element={<MercadoPagoOauthTokenStatusPage />} />
						<Route path="mercado-pago/oauth" element={<MercadoPagoOauthTokenPage />} />
					</Route>
					<Route path="eventos/">
						<Route path="" element={<Events />} />
						<Route path=":id" element={<EventPage />} />
						<Route path=":id/edit" element={<UpdateEventPage />} />
						<Route path="crear" element={<CreateEventPage />} />
						<Route path=":eventId/entradas/crear" element={<CreateTicketPage />} />
						<Route path=":eventId/entradas/:ticketId/editar" element={<UpdateTicketPage />} />
						<Route path=":id/invitaciones/crear" element={<CreateInvitePage />} />
						<Route path=":eventId/productos/crear" element={<CreateProductPage />} />
						<Route path=":eventId/productos/:productId/editar" element={<UpdateProductPage />} />
					</Route>
					<Route path="usuarios/">
						<Route path="" element={<UsersPage />} />
						<Route path="crear" element={<CreateCompanyUserPage />} />
					</Route>
					<Route path="predios/">
						<Route path="" element={<VenuesPage />} />
						<Route path="crear" element={<CreateVenuePage />} />
						<Route path=":id/editar" element={<UpdateVenuePage />} />
					</Route>
					<Route path="finanzas/">
						<Route path="" element={<FinancePage />} />
						<Route path="mercadopago/crear" element={<MercadoPagoOauthTokenCreatePage />} />
					</Route>
				</Route>
			</Routes>
			</BrowserRouter>
		</React.StrictMode>
	</ChakraProvider>,
	document.getElementById('root')
);

function GlobalRedirect(): null {
	const navigate = useNavigate();

	React.useEffect(() => {
		const token = sessionStorage.getItem('token');

		if (token) {
			navigate('/admin/');
		} else {
		navigate('/auth/sign-in');
		}
	}, [navigate]);

	return null;
}